<template>
  <div class="page">
    <top-bar/>
    <div class="info has-bar" v-if="!loading">
      <div class="list">
        <div class="list-item" v-for="(item, index) in list" :key="index">
          <div class="name">
            {{item.ItemName_CH}}
          </div>
          <div class="value">
            {{item.ItemValue_STR}}
            {{item.ItemUnits}}
            {{item.ResultCheckSign}}
            <span v-if="item.ValueRange">({{item.ValueRange}})</span>
          </div>
        </div>
      </div>
      <div class="xj">
        <div class="name">小结</div>
        <div class="value">
          {{VerdictName}}
        </div>
      </div>
      <div v-if="is_image" class="img-box">
        <img v-for="(item, index) in imgs" :src="item" :key="index" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import api from '../api'
export default {
  data () {
    return {
      VerdictName: "",
      list: [],
      is_image: false,
      imgs: [],
      loading: true
    }
  },
  methods: {
    getInfo (no) {
      api.get('report/result/item_details', { item_serial_no: no }).then(res => {
        if (res) {
          this.list = res.data.children
          this.VerdictName = res.data.detail.VerdictName
          if (res.data.detail.is_image === 1) {
            const base = 'http://report.hyjktj.com/api/report/result/get_image?token=' + this.$store.state.token + '&image_serial_no='
            res.data.detail.image_serial_no.forEach(item => {
              this.imgs.push(base + item)
            })
            this.is_image = true
          }
        }
      }).finally(() => this.loading = false)
    }
  },
  created () {
    const no = this.$route.query.no
    this.getInfo(no)
  }
}
</script>

<style lang="less" scoped>
.info {
  background-color: #F9FAFA;
  overflow: auto;
  color: #333333;
  padding: 32px 24px;
  .list {
    .list-item {
      display: flex;
      padding: 18px 0;
      border-bottom: 1px solid #EBEBEB;
      font-size: 14px;
      .name {
        width: 60%;
        flex-shrink: 0;
      }
      .value {
        width: 40%;
        flex-shrink: 0;
        text-align: right;
      }
    }
  }
  .xj {
    display: flex;
    margin-top: 16px;
    .name {
      flex-shrink: 0;
      width: 50%;
    }
    .value {
      width: 50%;
      flex-shrink: 0;
    }
  }
}
.img-box {
  margin-top: 18px;
  img {
    margin-top: 12px;
    display: block;
    width: 100%;
    height: auto;
  }
}
</style>
